<template>
    <front-layout>
        <v-container class="fill-height">
            <v-row class="text-center">
                <v-col cols="12">
                    <h2 class="text-uppercase">
                        {{this.$route.params.group}}
                    </h2>
                </v-col>
                <v-col cols="12">
                    <div>
                        <v-data-table
                            :headers="headers"
                            :items="formats"
                            class="elevation-1"
                            :hide-default-footer="true"
                            :items-per-page="-1"
                        >
                            <template v-slot:item.actions="{ item }">
                                <v-btn
                                    :to="item.route"
                                    class="white--text"
                                    color="primary"
                                    depressed
                                    x-small
                                >
                                    Open converter
                                </v-btn>
                            </template>
                        </v-data-table>
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </front-layout>
</template>

<script>
    import FrontLayout from "../../layouts/FrontLayout";
    import Formats from "../../api/Formats";
    export default {
        name: "ConverterGroups",
        components: {FrontLayout},
        data() {
            return {
                headers: [
                    { text: 'Format', value: 'format' },
                    { text: 'Actions', value: 'actions'},
                ],
                formats: []
            }
        },
        mounted() {
            this.fetchGroupFormats()
        },
        watch: {
            '$route'() {
                this.formats = []
                this.fetchGroupFormats()
            }
        },
        methods: {
            async fetchGroupFormats() {
                let formats = await Formats.groupFormats(this.$route.params.group);
                for (let key in formats) {
                    this.formats.push({ format: formats[key], route: { name: 'convert-formats', params: { format: formats[key] } } },)
                }
            }
        }
    }
</script>

<style scoped>

</style>
